export const YEAR_OPTIONS = ( ()=> {
    const startYear = 2021
    const currentYear = new Date().getFullYear();
    const years = [];
    for(let year = startYear; year<=currentYear;year++) {
        years.push(year);
    }
    return years;
})();
export const CURRENT_YEAR = ( ()=> {
    return new Date().getFullYear();
})();