<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-snackbar
                v-model="showPopup"
                :timeout="timeout"
                color="success"
                top
                center
                >
                {{ this.message }}
            </v-snackbar>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else
            class="sticky-table"
            :items="data"
            :search="search">
            <template v-slot:header="">
                <thead>
                <tr>
                    <th v-for="(header, index) in dataHeader" :key="index" :class="index === 0 ? 'sticky-column' : ''">
                    {{ header.text }}
                    </th>
                </tr>
                </thead>
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Login & Engagement
                                </v-toolbar-title>
                            </v-row>
                                <AFilterPaginate
                                    :filterHeaders="dataHeader"
                                    :data="data"
                                    :startDate="startDate"
                                    :endDate="endDate"
                                    @reset="(response) => {
                                        fetch()
                                    }"
                                    @filter="(startDate,endDate,keyword,category) => {
                                        ApplyFilter(startDate,endDate,keyword,category);
                                    }"/>
                                <div class="d-flex justify-start" >
                                    <v-btn
                                        color="primary"
                                        small
                                        @click="downloadReport"
                                        plain>
                                        Download Report
                                    </v-btn>
                                </div>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:body="{items}">
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td
                    @click="copyText(item.company_id)" class="sticky-column">
                        <ACopyAbleText :text="item.company_id">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.company_name)">
                        <ACopyAbleText :text="item.company_name">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.first_name)">
                        <ACopyAbleText :text="item.first_name">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.last_name)">
                        <ACopyAbleText :text="item.last_name">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.user_email)">
                        <ACopyAbleText :text="item.user_email">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.mobile)">
                        <ACopyAbleText :text="item.mobile"> 
                         </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.period_start_date)">
                        <ACopyAbleText :text="item.period_start_date" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.user_id)">
                        <ACopyAbleText :text="item.user_id" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.app)">
                        <ACopyAbleText :text="item.app" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.desktop_browser)">
                        <ACopyAbleText :text="item.desktop_browser" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.app_browser)">
                        <ACopyAbleText :text="item.app_browser" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.acc_category)">
                        <ACopyAbleText :text="item.acc_category" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.client_status)">
                        <ACopyAbleText :text="item.client_status" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.course)">
                        <ACopyAbleText :text="item.course" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.webinars)">
                        <ACopyAbleText :text="item.webinars" >
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.monthly_update_class)">
                        <ACopyAbleText :text="item.monthly_update_class" >
                        </ACopyAbleText>
                    </td> <td
                    @click="copyText(item.aae)">
                        <ACopyAbleText :text="item.aae" >
                        </ACopyAbleText>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../../components/common/ASuccessWithoutReload.vue';
import excelUtils from '../../../utils/excelUtils';
import AFilterPaginate from '../../common/filter/AFilterPaginate.vue';
import { isObject } from 'lodash';
    export default {
    components:{
    ASuccessWithoutReload,
    AFilterPaginate,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        showPopup:false,
        message:"Text copied succesfully",
        startDate:null,
        endDate:null,
        keyword:"",
        category:"company_id",
        timeout:2000,
        data:[],
        dataHeader:[
            {
                text:"Company id",
                value:"company_id"
            },
            {
                text:'Company name',
                value:'company_name'
            },
            {
                text:'First name',
                value:'first_name'
            },
            {
                text:"Last name",
                value:"last_name"
            },
            {
                text:'User email',
                value:'email',
            },
            {
                text:"Mobile",
                value:"mobile"
            },
            {
                text:"Period start date",
                value:"period_start_date"
            },
            {
                text:"User id",
                value:"user_id"
            },
            {
                text:"App",
                value:"app",
            },
            {
                text: "Desktop browser",
                value:"desktop_browser",
            },
            {
                text:"App browser",
                value:"app_browser",
            },
            {
                text:"Acc category",
                value:"acc_category",
            },
            {
                text:"Client status",
                value:"client_status"
            },
            {
                text:"Course",
                value:"course"  
            },
            {
                text:"Webinars",
                value:"webinars",
            },
            {
                text:"Monthly update class",
                value:"monthly_update_class",
            },
            {
                text:"AAE",
                value:"aae"
            },
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class === "weeklyReport") {
                
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {

        this.fetch();

    },
    methods: {
        fetch(){
            this.startDate = new Date();
            this.endDate = new Date();
            this.keyword = "";
            this.category = "company_id";
            this.startDate.setDate(this.startDate.getDate() - 30 );
            console.log(this.startDate,this.endDate);
            this.startDate = this.startDate.toISOString().split('T')[0]
            this.endDate = this.endDate.toISOString().split('T')[0]
            this.$api.fetch(this.fetchWeeklyEngagementAndLoginReport());
        },
       
       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchWeeklyEngagementAndLoginReport() {
            let tempApi = this.$_.clone(this.api);
            tempApi.url = process.env.VUE_APP_SERVER_API+"/analysis/weekly-report/engagement-and-login?start_date="+this.startDate+"&end_date="+this.endDate+"&keyword="+this.keyword+"&category="+this.category;
            tempApi.method = "GET";
            return tempApi;
        },
        ApplyFilter(startDate,endDate,keyword,category) {
            alert("vvv");
            this.startDate = startDate;
            this.endDate = endDate;
            this.keyword = keyword;
            if(isObject(category)) {
                this.category = category.value;
            }
            else {
                this.category = category;
            }
            let tempApi = this.$_.clone(this.api);
            tempApi.url = process.env.VUE_APP_SERVER_API+"/analysis/weekly-report/engagement-and-login?start_date="+startDate+"&end_date="+endDate+"&keyword="+keyword+"&category="+this.category;
            tempApi.method = "GET";
            this.$api.fetch(tempApi);
        },
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
            this.showPopup = true;
        },
        handleClosePopup() {
            this.showPopup = false;
        },
        downloadReport() {
            try{
                let downloadData = null;
                if(this.data.length > 0) {
                    downloadData = this.data.map(item=>{
                        return {
                            "Company id": item.company_id,
                            "Company name": item.company_name,
                            "First name": item.first_name,
                            "Last name": item.last_name,
                            "User email": item.user_email,
                            "Mobile": item.mobile,
                            "Period start date": item.period_start_date,
                            "User id": item.user_id,
                            "App": item.app,
                            "Desktop browser": item.desktop_browser,
                            "App browser": item.app_browser,
                            "Acc category": item.acc_category,
                            "Client status": item.client_status,
                            "Course": item.course,
                            "Webinars": item.webinars,
                            "Monthly update class": item.monthly_update_class,
                            "AAE": item.aae
                        }
                    });
                }
                else {
                    downloadData = [
                        {
                           "Company id": "",
                            "Company name": "",
                            "First name": "",
                            "Last name": "",
                            "User email": "",
                            "Mobile": "",
                            "Period start date": "",
                            "User id": "",
                            "App": "",
                            "Desktop browser": "",
                            "App browser": "",
                            "Acc category": "",
                            "Client status": "",
                            "Course": "",
                            "Webinars": "",
                            "Monthly update class": "",
                            "AAE": ""
                        }
                    ]
                }
              excelUtils.exportToExcel(downloadData,"login_and_engagement.xlsx");
            }catch(error) {
                alert(error)
            }
        }
    }
}
</script>
<style>
.sticky-table {
    overflow-x:scroll;
}
.sticky-column {
    position:sticky;
    left:0;
    z-index:1;
    background:#f8f9fa;
    border-right: 1px solid #ddd;
}
td {
    min-width:200px;
    text-align:center;
    font-family: Arial, sans-serif;
    border-right: 1px solid #ddd;
}
th {
    text-align:center!important;
    background:#f8f9fa;
    border-top:1px solid #ddd;
    border-right: 1px solid #ddd;
}

.v-data-footer {
    background-color:#f8f9fa;
}
</style>